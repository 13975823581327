<template>
  <div>
    <a-modal v-model="form_visible" width="750px" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">记录工作量</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" 
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }">
          <a-row gutter="12">

            <a-col :span="24" :md="12">
              <a-form-model-item prop="process" label="流程">
                <a-select v-model.number="form.process" style="width: 100%" :allowClear="true">
                  <a-select-option v-for="item of process_items" :key="item.process" :value="item.process">{{
                    item.process_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="worker_name" label="工人姓名">
                <a-input v-model="form.worker_name" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="quantity_done" label="完成个数" placeholder="">
                <a-input v-model.number="form.quantity_done" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="date" label="添加日期">
                <a-date-picker v-model="form.date" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" />
              </a-form-model-item>
            </a-col>
          </a-row>

        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { TaskProcessCreate,TaskProcessUpdate} from '@/api/production'
import moment from 'moment';
export default {
  name: 'WorkloadFormModal',
  props: ['form_visible', 'process_items','task_form','form'],
  model: { prop: 'form_visible', event: 'cancel' },
  data() {
    return {

      // form: {
      //   process: '',
      //   worker_name: '',
      //   quantity_done: '',
      //   date: moment().format('YYYY-MM-DD'),
      //   remark: '',
      // },

      rules: {
        process: [
          { required: true, message: '请选择流程', trigger: 'change' }
        ],
        // worker_name: [
        //   { required: true, message: '请输入工人姓名', trigger: 'blur' }
        // ],
        quantity_done: [
          { required: true, message: '请输入完成个数', trigger: 'blur' },
          { type: 'integer', message: '完成个数必须为整数', trigger: 'blur' },
        ]
      },

      loading: false,
    };
  },
  methods: {

    confirm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? TaskProcessUpdate : TaskProcessCreate;
          this.form.production_task = this.task_form.id
          this.form.date = moment(this.form.date).format('YYYY-MM-DD HH:mm:ss');
          if(!this.form.worker_name){
            this.form.worker_name = '默认工人'
          }
          func(this.form).then(data => {
            this.$message.success(this.form.id ? '修改成功' : '新增成功');
            this.$emit(this.form.id ? 'update' : 'create', data);
            this.cancel();
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    cancel() {
      this.$emit('cancel', false);
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    // this.initialize();
  },
}
</script>

<style scoped></style>